import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { APIErrorState } from 'types/redesignStore'

export const initialState: APIErrorState = {
  messages: [],
}

const apiErrorSlice = createSlice({
  name: 'apiError',
  initialState,
  reducers: {
    setAPIErrorMessage: (state, { payload: message }: PayloadAction<string>) => ({
      messages: [...state.messages, message],
    }),
    clearAPIErrorMessage: () => ({
      messages: [],
    }),
  },
})

export const { setAPIErrorMessage, clearAPIErrorMessage } = apiErrorSlice.actions
export default apiErrorSlice.reducer
