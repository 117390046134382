import { createListenerMiddleware, isRejectedWithValue } from '@reduxjs/toolkit'

import { setAPIErrorMessage } from 'redesignStore/slices/apiErrorSlice'
import { ErrorResponseDto, isErrorResponseDto } from 'utils/apiErrors'

// Create the middleware instance and methods
const apiListenerMiddleware = createListenerMiddleware()

type APIErrorAction = { data: ErrorResponseDto }

export function isAPIErrorAction(dto: unknown): dto is APIErrorAction {
  return !!dto && typeof dto === 'object' && 'data' in dto && isErrorResponseDto(dto.data)
}

apiListenerMiddleware.startListening({
  predicate: action =>
    isRejectedWithValue(action) &&
    !!action.payload &&
    isAPIErrorAction(action.payload) &&
    !!action.payload.data.humanReadableMessage,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setAPIErrorMessage((action.payload as APIErrorAction).data.humanReadableMessage as string))
  },
})

export default apiListenerMiddleware
