'use client'

import 'core-js/actual/array/to-reversed'
import 'core-js/actual/array/to-sorted'
import 'core-js/actual/array/to-spliced'
import 'core-js/actual/url-search-params' // URLSearchParams.size

export default function Polyfills() {
  return null
}
