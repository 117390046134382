'use client'

// error.tsx boundaries must be Client Components.
// https://nextjs.org/docs/app/api-reference/file-conventions/error

import React, { useEffect, useMemo, useState } from 'react'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import * as Sentry from '@sentry/nextjs'
import truncate from 'lodash/truncate'

import {
  ExternalPageContent,
  ExternalPageFooter,
  ExternalPageHeader,
  ExternalPageWrapper,
} from 'components/templates/ExternalPage'
import SupportEmailButton from 'components/templates/ExternalPage/SupportEmailButton'
import ExternalLayout from 'layout/ExternalLayout/ExternalLayout'

const computerStringMaxLength = 1000
const timeZone = 'Europe/London'

async function currentDateTimeFormatted(): Promise<string> {
  // Use Intl.DateTimeFormat to avoid importing date-fns-tz
  const currentDate = new Date()
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
    timeZoneName: 'short',
    timeZone,
  })
    .format(currentDate)
    .replace(/\//g, '.')
    .replace(',', '')
}

function useCurrentDateTime() {
  const [outputDate, setOutputDate] = useState<string>()

  // Lazily evaluate the formatted date
  useEffect(() => {
    currentDateTimeFormatted().then(d => setOutputDate(d))
  }, [])

  return outputDate
}

function useCurrentUrl() {
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  return url
}

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    if (error) {
      // Log the error to Sentry
      Sentry.captureException(error)
    }
  }, [error])

  const theme = useTheme()

  const url = useCurrentUrl()
  const outputDate = useCurrentDateTime()

  const pre = useMemo(() => {
    if (error) {
      return truncate(error.message, { length: computerStringMaxLength, omission: '...' })
    }

    return undefined
  }, [error])

  return (
    <ExternalLayout disableAutoLogout>
      <ExternalPageWrapper>
        <ExternalPageHeader>Sorry, something went wrong</ExternalPageHeader>
        <ExternalPageContent>
          <Typography gutterBottom variant="h2">
            The page
            {url && (
              <Typography variant="h5" fontWeight="normal" component="span" display="block" color="primary" noWrap>
                {url}
              </Typography>
            )}
            produced an error or you don’t have access to this page.
          </Typography>

          <Typography variant="body2" gutterBottom>
            Please send a screenshot of this page to our support team and we'll sort it out.
          </Typography>

          <Card sx={{ my: 4, textAlign: 'left', bgcolor: 'lightSurface.main' }}>
            <CardContent>
              <Typography variant="body2" gutterBottom>{`Date and time: ${outputDate}`}</Typography>

              <Typography sx={{ typography: 'body2', whiteSpace: 'pre-wrap', fontFamily: 'Monospace' }}>
                {pre}
              </Typography>
            </CardContent>
          </Card>

          <Stack
            sx={{
              flexDirection: 'row',
              gap: theme.spacing(3.75),
              mb: theme.spacing(5.25),
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SupportEmailButton variant="contained" color="primary" sx={{ mb: 2 }}>
              Contact Support
            </SupportEmailButton>
            <Button href="/active" variant="contained" color="primary" sx={{ mb: 2 }}>
              Go to Dashboard
            </Button>
          </Stack>
        </ExternalPageContent>
        <ExternalPageFooter />
      </ExternalPageWrapper>
    </ExternalLayout>
  )
}
