import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/ConsoleWarning.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/MuiXLicense.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/Polyfills.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/molecules/Modal/ModalWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/Providers.tsx");
