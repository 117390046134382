import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { UnknownAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import adminDocEditorApi from 'redesignStore/api/adminDocEditorApi'
import baseApi from 'redesignStore/api/baseApi'
import swaggerApi from 'redesignStore/api/swaggerApi'
import testRoutesApi from 'redesignStore/api/testRoutesApi'
import apiListenerMiddleware from 'redesignStore/middleware/apiListenerMiddleware'
import alertsReducer, { initialState as alertsInitialState } from 'redesignStore/slices/alertsSlice'
import apiErrorReducer, { initialState as apiErrorInitialState } from 'redesignStore/slices/apiErrorSlice'
import contextReducer, { initialState as contextInitialState } from 'redesignStore/slices/context/contextSlice'
import draftingNotesSliceReducer, {
  initialState as draftingNotesSliceInitialState,
} from 'redesignStore/slices/draftingNotes/draftingNotesSlice'
import electionsReducer, { initialState as electionsInitialState } from 'redesignStore/slices/elections/electionsSlice'
import notificationsReducer, {
  initialState as notificationsInitialState,
} from 'redesignStore/slices/notificationsSlice'
import presetsReducer, { initialState as presetsInitialState } from 'redesignStore/slices/presets/presetsSlice'
import umbrellaReducer, { initialState as umbrellaInitialState } from 'redesignStore/slices/umbrella/umbrellaSlice'
import workspaceReducer, { initialState as workspaceInitialState } from 'redesignStore/slices/workspaceSlice'
import {
  AlertsState,
  DraftingNotesState,
  ElectionsState,
  NotificationsState,
  PresetsState,
  UmbrellaState,
  WorkspaceState,
  APIErrorState,
} from 'types/redesignStore'
import { ContextState } from 'types/UserContext'

// Don't use this outside of this file, you almost certainly want AppState instead
interface RootState {
  context: ContextState
  draftingNotes: DraftingNotesState
  elections: ElectionsState
  workspace: WorkspaceState
  presets: PresetsState
  alerts: AlertsState
  umbrella: UmbrellaState
  notifications: NotificationsState
  apiError: APIErrorState
}

export function makeStore(partialPreloadedState?: Partial<RootState>) {
  const preloadedState: RootState | undefined = partialPreloadedState && {
    context: contextInitialState,
    draftingNotes: draftingNotesSliceInitialState,
    elections: electionsInitialState,
    workspace: workspaceInitialState,
    presets: presetsInitialState,
    alerts: alertsInitialState,
    umbrella: umbrellaInitialState,
    notifications: notificationsInitialState,
    apiError: apiErrorInitialState,
    ...partialPreloadedState,
  }

  return configureStore({
    reducer: {
      // These are intentionally not in RootState as we don't want them accessed directly
      [baseApi.reducerPath]: baseApi.reducer,
      [swaggerApi.reducerPath]: swaggerApi.reducer,
      [testRoutesApi.reducerPath]: testRoutesApi.reducer,
      [adminDocEditorApi.reducerPath]: adminDocEditorApi.reducer,

      context: contextReducer,
      draftingNotes: draftingNotesSliceReducer,
      elections: electionsReducer,
      workspace: workspaceReducer,
      presets: presetsReducer,
      alerts: alertsReducer,
      umbrella: umbrellaReducer,
      notifications: notificationsReducer,
      apiError: apiErrorReducer,
    },
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware()
        .concat(apiListenerMiddleware.middleware)
        .concat(baseApi.middleware)
        .concat(swaggerApi.middleware)
        .concat(testRoutesApi.middleware)
        .concat(adminDocEditorApi.middleware)
    },
    preloadedState,
  })
}

const store = makeStore()

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof store.getState>
export type AppThunkDispatch = ThunkDispatch<AppState, void, UnknownAction>

// export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const useAppDispatch: () => AppThunkDispatch = useDispatch

export default store
