import isEmpty from 'lodash/isEmpty'
import xor from 'lodash/xor'

import { AnswerSummary, LegacyNestedAnswersSummary, NestedAnswersSummary } from 'types/Negotiation'
import { PresetNestedAnswersSummary } from 'types/Preset'
import { ElectionColor } from 'utils/electionProgress/ElectionColor'

/**
 * The set of all colours that may appear in an election progress bar, with corresponding weights indicating how much of
 * that colour should be displayed for the given election.
 */
export type WeightedElectionColorSet = Record<ElectionColor, number>

export namespace WeightedElectionColorSet {
  export function calculateFromAnswerSummary(summary: AnswerSummary, isTemplate?: boolean): WeightedElectionColorSet {
    const max = 100
    const [completed, matching] = summary.amend
      ? [max, summary.amendMatched ? max : 0]
      : [summary.percentComplete, summary.percentMatched]
    const isCompleted = completed === max
    const showCompare = isCompleted && summary.cpHasAnswered

    return {
      success: !isTemplate && summary.agreed ? max : showCompare ? matching : 0,
      warning: showCompare ? max - matching : 0,
      grey: (!isTemplate && summary.agreed) || showCompare ? 0 : completed,
      transparent: showCompare ? 0 : max - completed,
    }
  }

  export function calculateFromNestedAnswersSummaries<T extends NestedAnswersSummary | PresetNestedAnswersSummary>(
    summaries: T[]
  ): WeightedElectionColorSet {
    if (isEmpty(summaries)) {
      return {
        success: 0,
        warning: 0,
        grey: 0,
        transparent: 0,
      }
    }

    const colorSets: WeightedElectionColorSet[] = summaries.map(summary => {
      const matchingElectionIds = 'matchingElectionIds' in summary ? (summary.matchingElectionIds ?? []) : []
      const answeredElectionsOtherSides =
        'answeredElectionsOtherSides' in summary ? (summary.answeredElectionsOtherSides ?? []) : []
      const completedElections =
        summary.completedElections ?? (summary as LegacyNestedAnswersSummary).completedElectionIds

      const [percentComplete, percentMatched] = isEmpty(summary.answeredElections)
        ? [0, 0]
        : [
            (completedElections.length * 100) / summary.answeredElections.length,
            (matchingElectionIds.length * 100) / summary.answeredElections.length,
          ]

      return calculateFromAnswerSummary({
        agreed: !isEmpty(completedElections) && isEmpty(xor(completedElections, matchingElectionIds)),
        completed: !isEmpty(completedElections) && isEmpty(xor(completedElections, summary.answeredElections)),
        matched:
          !isEmpty(completedElections) &&
          isEmpty(xor(completedElections, summary.answeredElections, matchingElectionIds)),
        percentComplete,
        percentMatched,
        totalVisible: summary.answeredElections,
        totalCompleted: completedElections,
        totalMatched: matchingElectionIds,
        amend: false,
        cpHasAnswered: !isEmpty(answeredElectionsOtherSides),
      })
    })

    const combined = (color: ElectionColor) => colorSets.reduce((acc, colorSet) => acc + colorSet[color], 0)

    return {
      success: combined('success'),
      warning: combined('warning'),
      grey: combined('grey'),
      transparent: combined('transparent'),
    }
  }
}
