import { FC, JSX, ReactElement } from 'react'

type ConditionalWrapperProps = {
  condition: boolean | string
  wrapper: (children: ReactElement) => JSX.Element
  children: ReactElement
}
const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

export default ConditionalWrapper
