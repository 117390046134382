'use client'

import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { usePathname, useSearchParams } from 'next/navigation'

import { useHubSpot } from 'components/integrations/HubSpot'
import { MatomoContext } from 'components/integrations/Matomo/Matomo.context'
import { MatomoAPI, MatomoState } from 'components/integrations/Matomo/Matomo.types'
import MatomoInstance from 'components/integrations/Matomo/MatomoInstance'
import { useAppSelector } from 'redesignStore'

export default function MatomoProvider({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const injected = useRef<HTMLElement | null>(null)
  const recordedUserId = useRef<string | null>(null)

  const loadMatomo = useCallback(
    async () =>
      new Promise<MatomoAPI>((resolve, reject) => {
        window._paq = window._paq ?? []
        window._paq.push(['setTrackerUrl', `${process.env.NEXT_PUBLIC_MATOMO_URL}/matomo.php`])
        window._paq.push(['setSiteId', parseInt(process.env.NEXT_PUBLIC_MATOMO_SITE_ID ?? '', 10)])

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.id = 'matomo-script-loader'
        script.async = true
        script.defer = true
        script.src = `${process.env.NEXT_PUBLIC_MATOMO_URL}/matomo.js`
        script.onload = () => {
          resolve(new MatomoInstance(window._paq))
        }
        script.onerror = (_event, _source, _lineno, _colno, error) => {
          reject(error ?? new Error(`Couldn't load Matomo script`))
        }
        document.body.appendChild(script)
        injected.current = script
      }),
    []
  )

  const [Matomo, setMatomo] = useState<MatomoAPI>()
  const { cookieConsent } = useHubSpot()
  const optIn = cookieConsent?.categories.analytics ?? false

  const userId = useAppSelector(state => state.context.userId)
  const loggedIn = !!userId

  useEffect(() => {
    if (typeof window._paq === 'undefined') {
      if (
        process.env.NEXT_PUBLIC_SENTRY_RELEASE !== 'DEV' && // Not in dev
        optIn && // Opt-ed in to functionality cookies
        !injected.current?.isConnected && // Not already injected
        typeof process.env.NEXT_PUBLIC_MATOMO_URL !== 'undefined' && // Has ANALYTICS_URL set
        typeof process.env.NEXT_PUBLIC_MATOMO_SITE_ID !== 'undefined' // Has ANALYTICS_SITE_ID set
      ) {
        loadMatomo()
          .then(setMatomo)
          .catch(() => {
            // Do nothing
          })
      }
    }
  }, [loadMatomo, optIn])

  // When the user logs in, record the user ID
  useEffect(() => {
    if (typeof Matomo !== 'undefined') {
      if (typeof userId !== 'undefined' && userId !== recordedUserId.current) {
        Matomo.setUserId(userId)
        recordedUserId.current = userId
      } else if (!loggedIn && !!recordedUserId) {
        // Logout
        Matomo.setUserId('')
        recordedUserId.current = null
      }
    }
  }, [Matomo, loggedIn, userId])

  // When the URL changes, record a page view
  useEffect(() => {
    if (pathname) {
      let searchString = searchParams?.toString()
      if (pathname === '/callback' && searchParams?.has('code')) {
        const mutableSearchParams = new URLSearchParams(Array.from(searchParams.entries()))
        mutableSearchParams.set('code', 'secret')
        searchString = mutableSearchParams.toString()
      }

      Matomo?.trackPageView(`${pathname}${searchString ? '?' : ''}${searchString}`)
    }
  }, [Matomo, pathname, searchParams])

  const childContext: MatomoState = useMemo(
    () => ({
      Matomo,
    }),
    [Matomo]
  )

  return <MatomoContext.Provider value={childContext}>{children}</MatomoContext.Provider>
}
