'use client'

import React, { useEffect, useMemo, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline'
import { LinkProps } from '@mui/material/Link'
import { menuItemClasses } from '@mui/material/MenuItem'
import { alpha, createTheme, darken, lighten, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { usePathname } from 'next/navigation'

import { latoLatinFont, latoLatinExtFont } from 'theme/fonts'

import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-data-grid/themeAugmentation'
import type {} from '@mui/x-charts/themeAugmentation'
import type {} from '@mui/x-charts-pro/themeAugmentation'

const NextLinkWrapper = React.forwardRef<HTMLAnchorElement, NextLinkProps>((props, ref) => (
  // Map href (Material UI) -> to NextLink
  <NextLink ref={ref} {...props} />
))

const Colours = {
  black: '#000000',
  purple: '#6750A4',
  grey: '#5C6B8A',
  green: '#008569',
  yellow: '#F5A623',
  blue: '#0172B1',
  pink: '#DC2773',
  red: '#B3261E',
} as const
export type PaletteColours = (typeof Colours)[keyof typeof Colours]

/**
 * Don't import this directly, instead use useTheme()
 */
const createiqThemeOptions: ThemeOptions = {
  product: 'createiq',
  transparentScrollbarsOnHover: {
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 6,
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme => alpha(theme.palette.secondary.main, 0.38),
        borderRadius: 5,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
      xxl: 2000,
    },
  },
  palette: {
    primary: {
      main: Colours.purple,
      contrastText: '#fff',
    },
    secondary: {
      main: Colours.grey,
      contrastText: '#fff',
    },
    primaryLight: {
      main: '#D0BCFF',
      contrastText: '#000000',
    },
    lightSurface: {
      main: '#EDEFFC',
      dark: '#1D192B',
      contrastText: '#000000',
    },
    darkSurface: {
      main: '#222653',
      contrastText: '#fff',
    },
    success: {
      main: Colours.green,
      contrastText: '#fff',
    },
    info: {
      main: Colours.pink,
      contrastText: '#fff',
    },
    warning: {
      main: Colours.yellow,
      contrastText: '#000000',
    },
    error: {
      main: Colours.red,
      contrastText: '#fff',
    },
    blackline: {
      main: Colours.blue,
      contrastText: '#fff',
    },
    divider: alpha(Colours.grey, 0.2),
    action: {
      hoverOpacity: 0.08,
      selectedOpacity: 0.12,
      disabledOpacity: 0.38,
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    highlight: {
      yellow: '#F5E5AD',
      red: '#F4DADA',
      purple: '#EEDFFB',
      green: '#DAEBCD',
    },
    external: {
      footer: '#fbfbfe',
    },
    text: {
      primary: 'rgba(0, 0, 0, 1)',
    },
  },
  typography: {
    fontFamily: `${latoLatinExtFont.style.fontFamily},${latoLatinFont.style.fontFamily}`,
    displayLarge: {
      fontSize: '3.5rem', // 56px: Display Large
      lineHeight: '64px',
      fontWeight: 400,
    },
    h1: {
      fontSize: '2.875rem', // 46px: Display medium
      lineHeight: '52px',
      fontWeight: 400,
    },
    displaySmall: {
      fontSize: '2.25rem', // 36px: Display small
      lineHeight: '44px',
      fontWeight: 400,
    },
    headlineLarge: {
      fontSize: '2rem', // 32px: Headline large
      lineHeight: '40px',
      fontWeight: 400,
      marginBottom: '1em',
    },
    headlineMedium: {
      fontSize: '1.75rem', // 28px: Headline medium
      lineHeight: '36px',
      fontWeight: 400,
      marginBottom: '1em',
    },
    h2: {
      fontSize: '1.5rem', // 24px: Headline small
      lineHeight: '32px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.375rem', // 22px: Title Large Black
      lineHeight: '28px',
      fontWeight: 900,
    },
    h4: {
      fontSize: '1.375rem', // 22px: Title Large
      lineHeight: '28px',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1rem', // 16px: Title medium
      lineHeight: '24px',
      fontWeight: 700,
      letterSpacing: '0.15px',
    },
    h6: {
      fontSize: '0.875rem', // 14px: Title small
      lineHeight: '20px',
      fontWeight: 700,
      letterSpacing: '0.1px',
    },
    subtitle1: {
      fontSize: '1rem', // 16px Body Medium
      lineHeight: '22px',
      fontWeight: 500,
      letterSpacing: '0.25px',
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px: Title small
      lineHeight: '20px',
      fontWeight: 700,
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '1rem', // 16px Body Medium
      lineHeight: '22px',
      fontWeight: 500,
      letterSpacing: '0.25px',
    },
    body2: {
      fontSize: '0.875rem', // 14px Body Small
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',
    },
    button: {
      fontSize: '0.875rem', // 14px Body Small === body2
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      textTransform: 'none', // default MUI is: uppercase
    },
    caption: {
      fontSize: '0.75rem', // 12px labelMedium === overline
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '0.5px',
      display: 'block',
    },
    overline: {
      fontSize: '0.75rem', // 12px labelMedium === caption
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '0.5px',
    },
    footNote: {
      fontSize: '0.875rem', // 14px
      lineHeight: '18px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      fontFamily: ['Times', 'Serif'].join(','),
    },
    code: {
      fontSize: '0.875rem', // 14px
      lineHeight: '20px',
      letterSpacing: '0.1px',
      fontFamily: 'Menlo, Consolas, "Droid Sans Mono", monospace',
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        variant: 'determinate',
      },
      styleOverrides: {
        root: () => ({
          borderRadius: '10px',
          height: '6px',
        }),
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 35,
        thickness: 5.5,
        variant: 'determinate',
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: '100vh',
          boxShadow: `0px 2px 5px ${alpha(theme.palette.darkSurface.main, 0.03)}`,
          position: 'relative',
        }),
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.darkSurface.main,
          color: theme.palette.primary.contrastText,
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          position: 'relative',
          whiteSpace: 'nowrap',
          overflowY: 'visible',
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a semantic tag.
          displayLarge: 'h1',
          displaySmall: 'h3',
          headlineLarge: 'h1',
          headlineMedium: 'h2',
          footNote: 'p',
        },
      },
      styleOverrides: {
        root: ({ ownerState: { gutterBottom } }) => ({
          ...(gutterBottom && {
            marginBottom: '1em',
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary', size } }) => ({
          borderRadius: '100px',
          padding: size === 'small' ? '6px 24px' : '10px 24px',
          minWidth: '64px',
          minHeight: size === 'small' ? 'min-content' : '40px',
          width: 'fit-content',
          textTransform: 'none',
          boxShadow: 'none',

          '&:focus-visible': {
            boxShadow: 'none',
            backgroundColor: alpha(theme.palette[color].main, 0.88),
          },
          '&:active': {
            boxShadow: 'none',
          },
        }),
        contained: ({ theme, ownerState: { color = 'primary' } }) => ({
          '&:hover': {
            backgroundColor: alpha(theme.palette[color].main, 0.92),
            boxShadow: `0px 1px 2px ${alpha(theme.palette.common.black, 0.03)}, 0px 1px 3px 1px ${alpha(
              theme.palette.common.black,
              0.15
            )}`,
          },
          '&:focus-visible': {
            backgroundColor: alpha(theme.palette[color].main, 0.88),
          },
          '&:active': {
            backgroundColor: alpha(theme.palette[color].main, 0.88),
          },
          '&:disabled': {
            backgroundColor: alpha(
              color === 'primaryLight' ? theme.palette.common.white : theme.palette.common.black,
              0.12
            ),
            color: alpha(color === 'primaryLight' ? theme.palette.common.white : theme.palette.common.black, 0.38),
          },
        }),
        text: ({ theme, ownerState: { color = 'primary' } }) => ({
          '&:hover': {
            backgroundColor: alpha(theme.palette[color].main, 0.08),
            boxShadow: 'none',
          },
          '&:focus-visible': {
            backgroundColor: alpha(theme.palette[color].main, 0.12),
          },
          '&:active': {
            backgroundColor: alpha(theme.palette[color].main, 0.12),
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: alpha(color === 'primary' ? theme.palette.common.white : theme.palette.common.black, 0.38),
            border: color === 'primaryLight' ? `1px solid ${alpha(theme.palette.common.white, 0.38)}` : 'none',
          },
        }),
        outlined: ({ theme, ownerState: { color = 'primary' } }) => ({
          '&:hover': {
            backgroundColor: alpha(theme.palette[color].main, 0.08),
            boxShadow: 'none',
          },
          '&:focus-visible': {
            backgroundColor: alpha(theme.palette[color].main, 0.12),
          },
          '&:active': {
            backgroundColor: alpha(theme.palette[color].main, 0.12),
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: alpha(color === 'primary' ? theme.palette.common.black : theme.palette.common.white, 0.38),
            border: color === 'primaryLight' ? `1px solid ${alpha(theme.palette.common.white, 0.38)}` : 'none',
          },
        }),
        sizeSmall: () => ({
          fontSize: '14px',
          minWidth: 'fit-content',
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.lightSurface.main,
          borderRadius: '20px',
          boxShadow: `0px 1px 4px 0px ${alpha(theme.palette.common.black, 0.25)}`,
        }),
        list: ({ theme }) => ({
          padding: theme.spacing(0.5, 0.75),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.75, 3),
          color: theme.palette.primary.main,
          borderRadius: '30px',

          '&:hover': {
            backgroundColor: alpha(theme.palette.lightSurface.dark, theme.palette.action.hoverOpacity),
            color: theme.palette.common.black,
          },
        }),
        focusVisible: ({ theme }) => ({
          backgroundColor: alpha(theme.palette.lightSurface.dark, theme.palette.action.selectedOpacity),
          color: theme.palette.primary.main,
        }),
        selected: ({ theme }) => ({
          backgroundColor: alpha(theme.palette.lightSurface.dark, theme.palette.action.selectedOpacity),
          color: theme.palette.primary.main,

          '&:hover': {
            backgroundColor: alpha(
              theme.palette.lightSurface.dark,
              theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
            ),
          },

          [`&.${menuItemClasses.focusVisible}`]: {
            backgroundColor: alpha(theme.palette.lightSurface.dark, theme.palette.action.selectedOpacity),
          },
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: NextLinkWrapper,
      } satisfies LinkProps, // Preserve styling of MUILink while having all props from NextLink as component underneath, useful for navigation with NextJS routing.
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: NextLinkWrapper,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: NextLinkWrapper,
      },
    },
    MuiTab: {
      defaultProps: {
        iconPosition: 'start',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: '48px',
          flex: '1',
          fontSize: `${theme.typography.subtitle2.fontSize}`,
          fontWeight: `${theme.typography.subtitle2.fontWeight}`,
        }),
      },
    },
    MuiTabs: {
      defaultProps: {
        scrollButtons: false,
        variant: 'standard',
      },
      styleOverrides: {
        root: ({ theme, ownerState: { indicatorColor } }) => ({
          ...(indicatorColor === 'primary' && {
            backgroundColor: theme.palette.lightSurface.main,
            '& .MuiTab-root': {
              color: `${theme.palette.secondary.main}`,
            },
            '& .Mui-selected': {
              color: `${theme.palette.common.black}`,
            },
          }),
          ...(indicatorColor === 'primaryLight' && {
            backgroundColor: theme.palette.darkSurface.main,
            '& .MuiTab-root': {
              color: `${theme.palette.common.white}`,
            },
            '& .Mui-selected': {
              color: `${theme.palette.common.white}`,
            },
          }),
        }),
        indicator: ({ theme, ownerState: { indicatorColor } }) => ({
          ...(indicatorColor === 'primaryLight' && {
            backgroundColor: `${theme.palette.primaryLight.main}`,
          }),
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary' } }) => ({
          color: theme.palette[color].main,
          padding: theme.spacing(1),
          '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.08),
          },
          '&.Mui-disabled': {
            color: alpha(theme.palette.common[color === 'primary' ? 'black' : 'white'], 0.38),
            cursor: 'not-allowed',
          },
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: alpha(theme.palette.common.black, 0.12),
          },
        }),
        checked: ({ theme, ownerState: { color = 'primary' } }) => ({
          color: theme.palette[color].main,
        }),
        indeterminate: ({ theme, ownerState: { color = 'primary' } }) => ({
          color: theme.palette[color].main,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary' } }) => ({
          width: '39px',
          height: '24px',
          padding: '0',
          overflow: 'visible',

          '.Mui-checked': {
            '.MuiSwitch-thumb': {
              boxSizing: 'border-box',
              position: 'relative',
              width: '18px',
              height: '18px',
            },

            '+ .MuiSwitch-track': {
              borderRadius: '75px',
              backgroundColor: color === 'primary' ? theme.palette.primary.main : theme.palette.primaryLight.main,
              border: '0',
            },

            '&.Mui-disabled + .MuiSwitch-track': {
              border: '0',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: color === 'primary' ? theme.palette.common.white : theme.palette.common.black,
            },
            '&.MuiButtonBase-root.Mui-disabled + .MuiSwitch-track': {
              background:
                color === 'primary' ? alpha(theme.palette.common.black, 0.12) : alpha(theme.palette.common.white, 0.12),
            },
            '&.MuiSwitch-switchBase': {
              padding: '3px 0px 3px 0px',
              margin: '0px 3px 0px 3px',
              '&.Mui-checked': {
                transform: 'translateX(16px)',
              },
            },
          },

          '.Mui-disabled': {
            '.MuiSwitch-thumb': {
              color:
                color === 'primary' ? alpha(theme.palette.common.black, 0.38) : alpha(theme.palette.common.white, 0.38),
            },
            '+ .MuiSwitch-track': {
              borderRadius: '12px',
              backgroundColor: color === 'primary' ? theme.palette.lightSurface.main : theme.palette.secondary.main,
              opacity: 1,
              border:
                color === 'primary'
                  ? `solid 2px ${alpha(theme.palette.common.black, 0.12)}`
                  : `solid 2px ${alpha(theme.palette.common.white, 0.12)}`,
            },
          },

          '.MuiSwitch-thumb:before': {
            content: '""',
            position: 'absolute',
            left: '-9px',
            top: '-9px',
            width: 'calc(100% + 18px)',
            height: 'calc(100% + 18px)',
            borderRadius: '75px',
            zIndex: '-1',
            background:
              color === 'primary' ? alpha(theme.palette.common.black, 0.08) : alpha(theme.palette.common.white, 0.08),
            opacity: '0',
            transition: 'opacity .2s ease-in',
          },
          '.Mui-checked .MuiSwitch-thumb:before': {
            content: '""',
            position: 'absolute',
            left: '-6px',
            top: '-6px',
            width: 'calc(100% + 12px)',
            height: 'calc(100% + 12px)',
            borderRadius: '75px',
            zIndex: '-1',
            background:
              color === 'primary'
                ? alpha(theme.palette.common.black, 0.08)
                : alpha(theme.palette.primaryLight.main, 0.08),
            opacity: '0',
            transition: 'opacity .2s ease-in',
          },

          '.MuiTouchRipple-root': {
            opacity: '0',
          },

          '&:hover': {
            '& .MuiButtonBase-root': {
              overflow: 'visible',
              '&.Mui-checked': {
                color: color === 'primary' ? theme.palette.primaryLight.main : theme.palette.primary.main,
              },
            },
            '.Mui-disabled .MuiSwitch-thumb:before': {
              opacity: '0',
            },
            '.MuiSwitch-thumb:before': {
              opacity: '1',
            },
          },
          '&:focus-visible': {
            '& .MuiButtonBase-root': {
              overflow: 'visible',
              '&.Mui-checked': {
                color: color === 'primary' ? theme.palette.primaryLight.main : theme.palette.primary.main,
              },
            },

            '.MuiSwitch-thumb:before': {
              opacity: '1',
            },

            '.Mui-disabled .MuiSwitch-thumb:before': {
              opacity: '0',
            },
          },
          '&:active': {
            '& .MuiButtonBase-root': {
              overflow: 'visible',
              '&.Mui-checked': {
                color: color === 'primary' ? theme.palette.primaryLight.main : theme.palette.primary.main,
              },

              '&:not([class*="disabled"])': {
                '.MuiSwitch-thumb': {
                  top: '-4px',
                  left: '-5px',
                  width: '20px',
                  height: '20px',
                  boxShadow: 'none',
                },
              },
            },

            '.MuiSwitch-thumb:before': {
              opacity: '1',
              left: '-5px',
              top: '-5px',
              width: 'calc(100% + 10px)',
              height: 'calc(100% + 10px)',
              background:
                color === 'primary' ? alpha(theme.palette.common.black, 0.12) : alpha(theme.palette.common.white, 0.12),
            },

            '.Mui-disabled .MuiSwitch-thumb:before': {
              opacity: '0',
            },

            '.Mui-checked:not([class*="disabled"]) .MuiSwitch-thumb': {
              width: '20px',
              height: '20px',
              top: '-1px',
              left: '-2px',
            },
          },
        }),

        switchBase: ({ theme, ownerState: { color = 'primary' } }) => ({
          color: color === 'primary' ? theme.palette.secondary.main : theme.palette.lightSurface.main,
          padding: '6px 0px 6px 0px',
          margin: '0px 6px 0px 6px',

          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 1,
            background: color === 'primary' ? theme.palette.common.white : alpha(theme.palette.common.black, 0.12),
          },
          '&.Mui-checked': {
            color: color === 'primary' ? theme.palette.common.white : theme.palette.darkSurface.main,
          },
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 1,
          },
          '&.MuiSwitch-switchBase:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-checked .MuiSwitch-input': {
            width: '36px',
            left: '-18px',
          },
          '.MuiSwitch-input': {
            width: '36px',
            left: '-6px',
          },
        }),
        thumb: () => ({
          boxSizing: 'border-box',
          position: 'relative',
          width: '12px',
          height: '12px',
        }),
        track: ({ theme, ownerState: { color = 'primary' } }) => ({
          boxShadow: 'none',
          borderRadius: '75px',
          backgroundColor: color === 'primary' ? theme.palette.lightSurface.main : theme.palette.secondary.main,
          opacity: 1,
          border:
            color === 'primary'
              ? `solid 2px ${theme.palette.secondary.main}`
              : `solid 2px ${theme.palette.lightSurface.main}`,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary' } }) => ({
          color: theme.palette[color].main,

          '.MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
            borderColor: color === 'primary' ? theme.palette.secondary.main : theme.palette.lightSurface.main,
          },
          '.MuiOutlinedInput-root.Mui-error > .MuiOutlinedInput-notchedOutline': {
            borderColor: color === 'primary' ? theme.palette.error.main : theme.palette.warning.main,
          },
          '.MuiOutlinedInput-root.Mui-disabled > .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.MuiInputBase-readOnly > .MuiOutlinedInput-notchedOutline':
            {
              borderColor:
                color === 'primary'
                  ? alpha(theme.palette.common.black, 0.38)
                  : alpha(theme.palette.lightSurface.main, 0.4),
            },
          '.MuiInputBase-root': {
            color: color === 'primary' ? '' : theme.palette.lightSurface.main,
          },
          '.MuiFormLabel-root': {
            color: color === 'primary' ? theme.palette.darkSurface.main : theme.palette.lightSurface.main,
          },
          '.MuiFormLabel-root.Mui-focused': {
            color: color === 'primary' ? theme.palette.primary.main : theme.palette.primaryLight.main,
          },
          '.MuiFormLabel-root.Mui-error': {
            color: color === 'primary' ? theme.palette.error.main : theme.palette.warning.main,
          },
          '.MuiFormLabel-root.Mui-disabled, .MuiFormLabel-root:has(+ .MuiInputBase-readOnly)': {
            color:
              color === 'primary'
                ? alpha(theme.palette.common.black, 0.38)
                : alpha(theme.palette.lightSurface.main, 0.4),
          },
          '.MuiFormHelperText-root': {
            color: theme.palette.common[color === 'primary' ? 'black' : 'white'],
          },
          '.MuiFormHelperText-root.Mui-error': {
            color: color === 'primary' ? theme.palette.error.main : theme.palette.warning.main,
          },
          '.MuiFormHelperText-root.Mui-disabled, .MuiInputBase-readOnly + .MuiFormHelperText-root': {
            color:
              color === 'primary'
                ? alpha(theme.palette.common.black, 0.38)
                : alpha(theme.palette.lightSurface.main, 0.4),
          },
          '.MuiSvgIcon-root': {
            color: color === 'primary' ? theme.palette.primary.main : theme.palette.lightSurface.main,
          },
          '.MuiInputAdornment-positionEnd > .MuiSvgIcon-root': {
            color: color === 'primary' ? theme.palette.error.main : theme.palette.warning.main,
          },
          '.Mui-disabled .MuiSvgIcon-root, .MuiInputBase-readOnly .MuiSvgIcon-root': {
            color:
              color === 'primary'
                ? alpha(theme.palette.common.black, 0.38)
                : alpha(theme.palette.lightSurface.main, 0.4),
          },
          '.MuiInputBase-input.Mui-disabled, .MuiInputBase-readOnly': {
            color: color === 'primary' ? 'black' : alpha(theme.palette.lightSurface.main, 0.4),
            WebkitTextFillColor: color === 'primary' ? 'black' : alpha(theme.palette.lightSurface.main, 0.4),
            cursor: 'not-allowed',

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
          },
          '.MuiInputBase-root.Mui-disabled': {
            cursor: 'not-allowed',
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiInputBase-input[readonly]': {
            color: theme.palette.common.black,
            WebkitTextFillColor: theme.palette.common.black,
            cursor: 'not-allowed',

            '+ .MuiOutlinedInput-notchedOutline': {
              borderColor: alpha(theme.palette.common.black, 0.38),
            },
          },

          '&.Mui-focused .MuiInputBase-input[readonly] + .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: alpha(theme.palette.common.black, 0.38),
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary' } }) => ({
          color: theme.palette[color].main,
          '&:hover': {
            backgroundColor: alpha(theme.palette.common[color === 'primary' ? 'black' : 'white'], 0.08),
          },
          '&.Mui-disabled': {
            color: alpha(theme.palette.common[color === 'primary' ? 'black' : 'white'], 0.38),
          },
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: alpha(theme.palette.common[color === 'primary' ? 'black' : 'white'], 0.12),
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'lightSurface', isError = false } }) => ({
          borderRadius: '8px',

          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: 'transparent',
          },

          '&:hover': {
            '&.MuiButtonBase-root': {
              backgroundColor: isError
                ? darken(theme.palette.error.main, 0.08)
                : color === 'lightSurface'
                  ? darken(theme.palette.lightSurface.main, 0.08)
                  : lighten(theme.palette.primary.main, 0.08),
              boxShadow:
                color === 'lightSurface' || isError
                  ? `0px 1px 2px ${alpha(theme.palette.common.white, 0.3)}, 0px 1px 3px 1px ${alpha(
                      theme.palette.common.black,
                      0.15
                    )}`
                  : 'none',
            },
          },
          '&:focus-visible, &:active': {
            '&.MuiButtonBase-root': {
              backgroundColor: isError
                ? darken(theme.palette.error.main, 0.12)
                : color === 'lightSurface'
                  ? darken(theme.palette.lightSurface.main, 0.12)
                  : lighten(theme.palette.primary.main, 0.12),
              boxShadow: 'none',
            },
          },
        }),
        deletable: ({ theme, ownerState: { color = 'lightSurface', isError = false } }) => ({
          '.MuiSvgIcon-root .MuiChip-icon': {
            color: color === 'lightSurface' && !isError ? theme.palette.common.black : theme.palette.common.white,
          },
        }),
        icon: {
          width: '18px',
          height: '18px',
        },
        deleteIcon: {
          width: '18px',
          height: '18px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary' } }) => ({
          borderColor:
            color === 'primary'
              ? alpha(theme.palette.primaryLight.main, 0.5)
              : alpha(theme.palette.secondary.main, 0.2),
          backgroundColor:
            color === 'primary'
              ? alpha(theme.palette.primaryLight.main, 0.5)
              : alpha(theme.palette.secondary.main, 0.2),
        }),
        vertical: {
          marginRight: '6px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.black,
          border: 0,
          '&:first-of-type': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
          '&:last-of-type': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        }),
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.common.black,
          borderRadius: '8px',
          minHeight: 17,
          padding: theme.spacing(0, 1),
          color: theme.palette.common.white,
          alignItems: 'center',
          display: 'flex',
        }),
        popper: {
          '&[data-popper-placement*="bottom"] .MuiTooltip-tooltipPlacementBottom': {
            marginTop: 0,
          },
          '&[data-popper-placement*="top"] .MuiTooltip-tooltipPlacementBottom': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: ({ theme, ownerState: { color = 'primary' } }) => ({
          '& .MuiSnackbarContent-root': {
            background: color === 'primary' ? theme.palette.common.black : theme.palette.common.white,
            borderRadius: '10px',
            width: 'fit-content',
            minWidth: '345px',
            boxShadow: `0px 4px 8px 3px ${alpha(theme.palette.common.black, 0.15)}`,
            '.MuiSnackbarContent-message': {
              color: color === 'primary' ? theme.palette.lightSurface.main : theme.palette.common.black,
              whiteSpace: 'pre-wrap',

              '& .MuiSvgIcon-root': {
                color: color === 'primary' ? theme.palette.common.white : theme.palette.common.black,
              },
            },
          },
          '.MuiButtonBase-root': {
            gap: '10px',
          },

          '.MuiSvgIcon-root': {
            gap: '10px',
            color: color === 'primary' ? theme.palette.primaryLight.main : theme.palette.primary.main,
          },
          '.MuiButton-text': {
            padding: '10px 12px 10px 12px',
            color: color === 'primary' ? theme.palette.primaryLight.main : theme.palette.primary.main,
          },
        }),
      },
    },
    MuiBarChartPro: {
      defaultProps: {
        leftAxis: {
          disableLine: true,
          disableTicks: true,
        },
        bottomAxis: {
          disableLine: true,
          disableTicks: true,
        },
        grid: {
          horizontal: true,
        },
        slotProps: {
          bar: {
            ry: 9,
            rx: 9,
          },
          legend: {
            hidden: true,
          },
        },
        yAxis: [{ tickMinStep: 10 }],
        barLabel: 'value',
        tooltip: {
          trigger: 'none',
        },
      },
    },
    MuiBarLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fill: theme.palette.primary.main,
          fontSize: '16px',
          fontWeight: 700,
        }),
      },
    },
    MuiChartsAxis: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`.MuiChartsAxis-tickLabel`]: {
            fill: theme.palette.secondary.main,
          },
        }),
      },
    },
    MuiChartsAxisHighlight: {
      styleOverrides: {
        root: () => ({
          display: 'none',
        }),
      },
    },
    MuiChartsLegend: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiChartsLegend-item': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiChartsSurface: {
      styleOverrides: {
        root: () => ({
          '.MuiChartsReferenceLine-root': {
            transform: 'translateX(12%)',
          },
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: () => ({
          maxWidth: 'none',
        }),
        rounded: {
          borderRadius: '20px',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
    },
    MuiBadge: {
      defaultProps: {
        color: 'info',
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: () => ({ alignItems: 'center' }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: () => ({
          borderRadius: '50%',
        }),
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: <CircularProgress color="inherit" size={16} thickness={5.5} variant="indeterminate" />,
      },
    },
  },
}

const isdaThemeOptions: ThemeOptions = {
  ...createiqThemeOptions,
  product: 'isda',
}

export function RedesignThemeProvider({ children, ...props }: Omit<Parameters<typeof ThemeProvider>[0], 'theme'>) {
  const [product, setProduct] = useState<'createiq' | 'isda'>(
    process.env.NEXT_PUBLIC_PRODUCT === 'isda' ? 'isda' : 'createiq'
  )
  const pathName = usePathname()

  useEffect(() => {
    const hasThemeParam = window.location.hash === '#theme=isda'

    const allowedThemeablePathNames = ['/login', '/forgot', '/technical-requirements', '/invite', '/lookup']

    const isAllowedThemeablePath =
      pathName && (allowedThemeablePathNames.some(item => pathName.startsWith(item)) || ['/', ''].includes(pathName))

    // ISDA theme can be toggled on for certain pages, managed by nginx redirects
    if (hasThemeParam && process.env.NEXT_PUBLIC_CREATE_DOMAIN) {
      if (isAllowedThemeablePath) {
        window.sessionStorage.setItem('theme', 'isda')
        window.location.hash = ''
      }
    } else if (!isAllowedThemeablePath) {
      window.sessionStorage.removeItem('theme')
    }

    setProduct(window.sessionStorage.getItem('theme') === 'isda' ? 'isda' : 'createiq')
  }, [pathName])

  const theme = useMemo(() => {
    // Temporary two-stage creation of theme in order to augment colours
    // https://mui.com/material-ui/customization/palette/#generate-tokens-using-augmentcolor-utility
    const theme = createTheme(product === 'createiq' ? createiqThemeOptions : isdaThemeOptions)
    return createTheme(theme, {
      palette: {
        primary: theme.palette.augmentColor({
          color: theme.palette.primary,
          lightShade: 50,
          name: 'primary',
        }),
        primaryLight: theme.palette.augmentColor({
          color: theme.palette.primaryLight,
          name: 'primaryLight',
        }),
      },
    })
  }, [product])

  return (
    <ThemeProvider theme={theme} {...props}>
      <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ monthShort: 'MMMM' }}>
        <CssBaseline />
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default {}
