import { Dispatch } from 'react'

import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { isErrorWithMessage, isFetchBaseQueryError } from 'redesignStore/api/utils/common'
import { Change } from 'types/Activities'

type BaseErrorResponseDto = {
  code: string
  message: string
  causes?: CauseType[]
  humanReadableMessage?: string
}

export type ErrorResponseDto = BaseErrorResponseDto & {
  _type: 'ai.nakhoda.create.common.dtos.ErrorResponseDto'
}

export type DocumentErrorResponseDto = BaseErrorResponseDto & {
  _type: 'ai.nakhoda.create.common.dtos.DocumentErrorResponseDto'
  documentCauses?: Change[]
}

export type CauseType = {
  causeType: string
  description: string

  // Only for NegotiationErrors
  errorType?: string
  electionId?: string
}

export function isErrorResponseDto(dto: unknown): dto is ErrorResponseDto {
  return (
    !!dto && typeof dto === 'object' && '_type' in dto && dto._type === 'ai.nakhoda.create.common.dtos.ErrorResponseDto'
  )
}

export function isDocumentErrorResponseDto(dto: unknown): dto is DocumentErrorResponseDto {
  return (
    !!dto &&
    typeof dto === 'object' &&
    '_type' in dto &&
    dto._type === 'ai.nakhoda.create.common.dtos.DocumentErrorResponseDto'
  )
}

export const isNotActiveUserError = (error: FetchBaseQueryError | SerializedError | undefined): boolean => {
  if (isFetchBaseQueryError(error) && isErrorResponseDto(error.data)) {
    if (error.data.code === 'negotiation.business_logic_error.not_active_user') {
      return true
    }
  }
  return false
}

export const humanReadableErrors: Record<BaseErrorResponseDto['code'], string> = {
  'negotiation.business_logic_error.upload_signature_not_allowed':
    'Signature page could not be uploaded. Please contact support.',
  'execution_attachment.errors.already_exists': 'A file already exists with this name, please rename the file',
  'esign.errors.missing_signatories':
    'An incorrect e-sign signatory was detected, please review the email addresses for your signatories on the elections view',
  'negotiation.display_reference_ids_conflict': 'Sorry, the Reference ID already exists within this workspace.',
  'account.invalid_invitation': 'Your registration request code is not valid.',
  'accounts.activation.pending_status_expected': 'An account has already been registered.',
  'negotiation.business_logic_error.not_same_entity_on_both_side': 'Not allowed to have the same entity on both sides',
  'input.invalid.domain': 'Unsupported email domain',
  'account.entity_already_exists': 'The entity is already assigned to a workspace.',
  'announcements.file_size_too_large': 'The image is too large. Maximum size is 200KB.',
  'announcements.incorrect_media_type_detected': 'The image is not supported. Please upload a .png file.',
}

function makeHumanReadableError(errorDto: BaseErrorResponseDto, customErrorMessage?: string): string {
  return humanReadableErrors[errorDto.code] ?? customErrorMessage ?? errorDto?.message
}

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError | unknown,
  customErrorMessage?: string
): string {
  if (isFetchBaseQueryError(error)) {
    if (isErrorResponseDto(error.data) || isDocumentErrorResponseDto(error.data)) {
      return makeHumanReadableError(error.data, customErrorMessage)
    }
  }

  if (isErrorWithMessage(error)) {
    return customErrorMessage ?? error?.message
  }

  return customErrorMessage ?? 'An unexpected error has occurred'
}

export async function handleMutationError<T>(
  promise: Promise<T>,
  setError: Dispatch<React.SetStateAction<string | undefined>>
) {
  try {
    return await promise
  } catch (error) {
    if (isFetchBaseQueryError(error)) {
      setError(getErrorMessage(error))
    }
  }
}
