'use client'

import React, { useCallback, useEffect, useState } from 'react'

import { backdropClasses } from '@mui/material/Backdrop'
import Badge, { BadgeProps } from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Popover, { popoverClasses } from '@mui/material/Popover'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { alpha, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { usePathname } from 'next/navigation'

import { CIQAvatar } from 'components/atoms/CIQAvatar'
import ConditionalWrapper from 'components/atoms/ConditionalWrapper'
import { accounts, users } from 'components/organisms/UserSwitcher/UserSwitcher.consts'
import { UserFormData, UserSwitcherUser } from 'components/organisms/UserSwitcher/UserSwitcher.types'
import {
  buildEmail,
  buildUser,
  currentUserEmailFromContext,
} from 'components/organisms/UserSwitcher/UserSwitcher.utils'
import useLogout from 'hooks/useLogout'
import { useAppSelector } from 'redesignStore'
import { useSignInMutation, useSubmitEmailMutation } from 'redesignStore/api/loginApi/loginApi'
import useAuthEndpoints from 'redesignStore/api/loginApi/utils/useAuthEndpoints'
import { useUpsertUserMutation } from 'redesignStore/api/testRoutesApi'
import { removeRedesignOptInCookie } from 'redesignStore/api/utils/common'
import { ProfileColour, UserRoles } from 'types/UserContext'

export default function UserSwitcher() {
  const [userSwitcherAnchorEl, setUserSwitcherAnchorEl] = useState<HTMLElement | null>(null)
  const userSwitcherOpen = Boolean(userSwitcherAnchorEl)
  const pathName = usePathname()

  const [formData, setFormData] = useState<UserFormData>({
    accountName: accounts[0].name,
    accountAllowedDomain: accounts[0].domain,
    userFirstName: 'YOURNAME',
    userLastName: 'YOURSURNAME',
    userEmail: `YOURNAME-ROLE@${accounts[0].domain}`,
    userPassword: 'ISDAPass01!',
    roleType: 'admin',
  })

  const namedUsers = process.env.NEXT_PUBLIC_USER_SWITCHER_USERS
  const userToFilter = namedUsers ? namedUsers.split(' ') : undefined
  const filteredUsers = userToFilter ? users.filter(u => userToFilter.some(f => u.userEmail.includes(f))) : users

  useEffect(() => {
    setFormData(formData => {
      let updated: UserFormData = { ...formData }

      function updateFromLocalStorage<TKey extends keyof UserFormData, TValue = UserSwitcherUser[TKey]>(
        input: UserFormData,
        key: TKey
      ) {
        const fromLocalStorage = window.localStorage.getItem(key)
        if (fromLocalStorage) {
          return { ...input, [key]: fromLocalStorage as TValue }
        }

        return input
      }

      updated = updateFromLocalStorage(updated, 'accountName')
      updated = updateFromLocalStorage(updated, 'accountAllowedDomain')
      updated = updateFromLocalStorage(updated, 'userFirstName')
      updated = updateFromLocalStorage(updated, 'userLastName')
      updated = updateFromLocalStorage(updated, 'userEmail')
      updated = updateFromLocalStorage(updated, 'userPassword')
      updated = updateFromLocalStorage(updated, 'roleType')

      return updated
    })

    const handler = (event: CustomEvent<UserFormData>) => {
      setFormData(formData => ({ ...formData, ...event.detail }))
    }

    window.User = {
      setState(data: UserFormData) {
        document.dispatchEvent(new CustomEvent('customuser', { detail: data }))
      },
    }

    document.addEventListener('customuser', handler)
    return () => document.removeEventListener('customuser', handler)
  }, [])

  const openUserSwitcher = (event: React.MouseEvent<HTMLElement>) => {
    setUserSwitcherAnchorEl(event.currentTarget)
  }
  const closeUserSwitcher = () => {
    setUserSwitcherAnchorEl(null)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = event.target
    window.localStorage.setItem(name, value)
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const theme = useTheme()
  const isValidForm = formData.userFirstName.length >= 2 && formData.userLastName.length >= 2 && !!formData.roleType

  const [buttonEl, setButtonEl] = useState<HTMLElement | null>(null)
  useEffect(() => {
    if (buttonEl && process.env.NEXT_PUBLIC_PREOPEN_USER_SWITCHER === 'true' && pathName === '/login') {
      setUserSwitcherAnchorEl(buttonEl)
    }
  }, [buttonEl, pathName])

  // Keyboard shortcut, Ctrl+Alt+P even if invisible
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey && event.altKey && !event.repeat && event.code === 'KeyP') {
        setUserSwitcherAnchorEl(existing => (existing ? null : buttonEl))
      }
    },
    [buttonEl]
  )
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  const [upsertUser] = useUpsertUserMutation()

  const { authEndpoints, generateRedirectURI } = useAuthEndpoints()
  const [submitEmail] = useSubmitEmailMutation()

  const [signIn] = useSignInMutation()
  const logout = useLogout()

  const loginAs = useCallback(
    async (user: UserFormData) => {
      if (authEndpoints) {
        try {
          await upsertUser(user).unwrap()
          if (Cookies.get('JWT')) {
            try {
              await logout()
              removeRedesignOptInCookie()
            } catch (e) {
              console.info('Ignoring error logging out previous user', e)
            }
          }

          const { sso } = await submitEmail({ username: user.userEmail }).unwrap()
          if (!sso) {
            const { sessionToken } = await signIn({
              configuration: authEndpoints,
              username: user.userEmail,
              password: user.userPassword,
            }).unwrap()

            window.location.href = generateRedirectURI({ type: 'sessionToken', sessionToken })
          }
        } catch (e) {
          console.error('TODO: Handle error', e)
        }
      }
    },
    [authEndpoints, generateRedirectURI, logout, signIn, submitEmail, upsertUser]
  )

  const currentUserEmailAddress = useAppSelector(state => currentUserEmailFromContext(state.context))

  if (!authEndpoints) return null

  return (
    <>
      <Box sx={{ display: 'inline-flex' }} ref={setButtonEl}>
        <IconButton
          color="primary"
          onClick={openUserSwitcher}
          data-qa="user-card__content"
          sx={{
            display: {
              xs: 'none',
              md: process.env.NEXT_PUBLIC_INVISIBLE_USER_SWITCHER === 'true' ? 'none' : 'inline-flex',
            },
          }}
        >
          <CIQAvatar size="small" firstName="Login" color={theme.palette.primary.main as ProfileColour} />
        </IconButton>
      </Box>
      <Modal open={userSwitcherOpen} onClose={closeUserSwitcher} closeAfterTransition disableEnforceFocus>
        <Grow appear in={userSwitcherOpen}>
          <Box>
            <Box
              component="form"
              noValidate
              sx={{
                maxWidth: '536px',
                mx: 'auto',
                position: 'fixed',
                left: '5%',
                top: '50%',
                transform: 'translate(0, -50%)',
                width: '45%',
                background: alpha(theme.palette.background.paper, 0.95),
                borderRadius: '20px',
                padding: '20px',
              }}
              mb={2}
            >
              <Stack direction="row" spacing={1} useFlexGap mb={1}>
                <TextField
                  variant="filled"
                  label="First name"
                  autoFocus
                  type="text"
                  name="userFirstName"
                  onChange={handleInputChange}
                  value={formData.userFirstName}
                  error={formData.userFirstName.length < 2}
                  helperText={formData.userFirstName.length < 2 ? 'Must be at least 2 characters' : undefined}
                  inputProps={{
                    'data-qa': 'first-name',
                  }}
                  sx={{ flexGrow: 1 }}
                />
                <TextField
                  variant="filled"
                  label="Last name"
                  type="text"
                  name="userLastName"
                  onChange={handleInputChange}
                  value={formData.userLastName}
                  error={formData.userLastName.length < 2}
                  helperText={formData.userLastName.length < 2 ? 'Must be at least 2 characters' : undefined}
                  inputProps={{
                    'data-qa': 'last-name',
                  }}
                  sx={{ flexGrow: 1 }}
                />
              </Stack>
              <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
                <InputLabel id="roleType-label">Role</InputLabel>
                <Select
                  labelId="roleType-label"
                  label="Role"
                  name="roleType"
                  onChange={handleInputChange}
                  value={formData.roleType}
                  inputProps={{
                    'data-qa': 'user-role',
                  }}
                >
                  {Object.entries(UserRoles).map(([roleType, roleName]) => (
                    <MenuItem key={roleType} value={roleType}>
                      {roleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {accounts.map((account, index) => {
                const userEmail = buildEmail(formData, account)

                return (
                  <Box mb={2} key={account.domain}>
                    <Typography variant="body2" mb={0.5}>
                      {userEmail}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => loginAs(buildUser(formData, account))}
                      disabled={!isValidForm}
                      data-qa={`create-user-btn${index + 1}`}
                    >
                      Login with test Account {index + 1}
                    </Button>
                  </Box>
                )
              })}
              <Typography variant="body2" mb={0.5}>
                {formData.userEmail}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => loginAs(formData)}
                disabled={!isValidForm}
                sx={{ mb: 2 }}
              >
                Login with custom user (using window.User.setState)
              </Button>
            </Box>
          </Box>
        </Grow>
      </Modal>
      <Popover
        open={userSwitcherOpen}
        onClose={closeUserSwitcher}
        anchorEl={userSwitcherAnchorEl}
        disableEnforceFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          pointerEvents: 'none',

          [`& .${backdropClasses.root}`]: {
            display: 'none',
          },

          [`& .${popoverClasses.paper}`]: {
            pointerEvents: 'auto',
            background: 'none',
            boxShadow: 'none',
            borderRadius: 0,
          },
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 0.5, m: 1 }} data-qa="user-switcher-users">
          {filteredUsers.map(user => {
            const emailDomain = user.userEmail.split('@').at(-1)
            const roleBadge = user.roleType
              .split('_')
              .map(s => s[0])
              .join('')
              .toUpperCase()

            let roleBadgeColor: BadgeProps['color']
            switch (user.roleType) {
              case 'super_manager':
              case 'admin':
              case 'read_only_manager':
              case 'read_only_user_manager':
                roleBadgeColor = 'primary'
                break
              case 'editor':
                roleBadgeColor = 'secondary'
                break
              case 'approver':
                roleBadgeColor = 'warning'
                break
              case 'manager':
                roleBadgeColor = 'success'
                break
              case 'nakhoda_super_manager':
              case 'nakhoda_manager':
              case 'nakhoda_read_only':
              case 'nakhoda_digitiser':
              case 'nakhoda_dev':
              case 'nakhoda_support':
              case 'nakhoda_document_service':
              case 'nakhoda_reports_service':
                roleBadgeColor = 'error'
                break
              case 'auditor':
              case 'one_link_editor':
              default:
                roleBadgeColor = 'info'
            }

            return (
              <ConditionalWrapper
                key={`wrapper-${user.userEmail}`}
                condition={!!user.disabled}
                wrapper={children => (
                  <Tooltip
                    title={'User disabled in this environment. Please login normally.'}
                    placement="left"
                    disableInteractive
                  >
                    {/* Span wrapper for tooltips on disabled buttons */}
                    <span>{children}</span>
                  </Tooltip>
                )}
              >
                <Button
                  key={user.userEmail}
                  variant="contained"
                  color="primaryLight"
                  size="small"
                  sx={{ width: '100%', justifyContent: 'flex-end', gap: 1, px: 3.5 }}
                  disabled={user.userEmail === currentUserEmailAddress || !!user.disabled}
                  onClick={() => loginAs(user)}
                >
                  <Stack direction="column" alignItems="flex-end">
                    <Typography variant="body1">
                      {user.userFirstName} {user.userLastName}
                    </Typography>
                    <Typography variant="body2">@{emailDomain}</Typography>
                  </Stack>
                  <Badge badgeContent={roleBadge} color={roleBadgeColor}>
                    <CIQAvatar firstName={user.userFirstName} src={user.avatar} />
                  </Badge>
                </Button>
              </ConditionalWrapper>
            )
          })}
        </Box>
      </Popover>
    </>
  )
}
